
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













.custom-header {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__title {
    font-size: 1.4rem;
    color: #8b8b8b;
    font-weight: normal;
  }

  &__icon {
    display: flex;
    margin-left: 0.5rem;
    flex: 0 0 auto;

    > svg {
      fill: #8b8b8b;
    }
  }
}
