
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































.custom-pagination {
  margin: 4rem 0;

  &__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  &__item {
    background-color: #efefef;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    font-size: 1.6rem;
    margin: 0 0.5rem;
    font-weight: 600;
    justify-content: center;
    color: $c-black;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &.is-active {
      background-color: $c-main-primary;
      color: $c-white;
    }

    &:hover {
      background-color: $c-main-primary;
      color: $c-white;
    }
  }

  &__filler {
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin: 0 0.5rem;
  }

  .arrow-container {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
  }

  .arrow-prev,
  .arrow-next {
    cursor: pointer;

    @media screen and (max-width: 767px) {
      background: #f6f6f6;
      border-radius: 100px;
      margin-inline: 1em;
    }

    &.disable {
      cursor: unset;
      opacity: 0.5;
    }

    &:hover {
      > svg {
        opacity: 0.5;
      }
    }
  }

  &__number {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
